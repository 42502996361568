.header {
  position: absolute;
  z-index: 30;
  top: 0;
  left: 0;
  width: 100%;
  padding: 15px;
}

@media (min-width: $desktop-width) {
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 40px;
    padding-left: 40px;
  }
}