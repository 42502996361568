.button {
  display: inline-block;
  border: none;
  font-weight: 400;
  font-size: 16px;
  padding: 20px 40px;
  transition: all 0.3s;
  cursor: pointer;
}

.button__opacity {
  border: 1px solid #ffffff;
  color: #ffffff;
  background: transparent;
  transition: all 0.3s;
  &:hover {
    background-color: #ffffff;
    color: #000000;
  }
}