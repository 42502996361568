.promo__list {
  @include list-reset;
  margin: 0;
}
.promo__item {
  position: relative;
  background-color: #000000;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  &::before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(0deg, rgba(117, 107, 107, 0.4), rgba(117, 107, 107, 0.4)), linear-gradient(0deg, rgba(19, 95, 189, 0.19), rgba(19, 95, 189, 0.19));
  }
}

.promo__inner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 10;
  min-height: 100vh;
  padding: 15px;
}

.promo__desc {
  color: #f8f8f8;
  font-size: 14px;
  font-weight: 300;
  text-transform: uppercase;
  text-align: center;
  margin: 0;
}

.promo__title {
  font-family: $marmelad;
  font-size: 30px;
  line-height: 1.5em;
  text-transform: uppercase;
  color: #ffffff;
  text-align: center;
  margin: 20px 0 35px 0;
}

.promo {
  position: relative;
  .slick-arrow {
    content: "";
    display: block;
    position: absolute;
    z-index: 20;
    right: 20px;
    bottom: 20px;
    width: 56px;
    height: 42px;
    border: none;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    padding: 0;
    background-color: transparent;
    background-image: url(../../img/promo_arrow.svg);
    font-size: 0;
  }

  .slick-prev {
    right: 90px;
    transform: rotate(180deg);
  }
}

@media (min-width: $tablet-width) {
  .promo__desc {
    font-size: 20px;
  }

  .promo__title {
    font-size: 65px;
    margin: 25px 0 75px 0;
  }
}

@media (min-width: $desktop-width) {
  .promo {
    .slick-arrow {
      top: calc(50% - 60px);
      bottom: auto;
    }

    .slick-prev {
      right: auto;
      left: 20px;
    }
  }
}