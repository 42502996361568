.tour {
  @include list-reset;
  margin: 0;
}

.tour__title {
  font-weight: 400;
  font-size: 18px;
  transition: all 0.3s;
}

.tour__desc {
  margin: 15px 0 0;
  font-weight: 300;
  font-size: 16px;
  transition: all 0.3s;
}

.tour__item {
  padding: 20px 15px;
  border-bottom: 1px solid #000;
  color: #000000;
  transition: all 0.3s;
  &:hover {
    color: $hover-color;
  }
}

@media (min-width: $tablet-width) {
  .tour {
    display: flex;
    position: absolute;
    z-index: 20;
    bottom: 0;
    left: 0;
    width: 100%;
    border-top: 1px solid rgba(255, 255, 255, 0.3);
  }

  .tour__item {
    flex-grow: 1;
    color: #ffffff;
    border-bottom: none;
    padding: 30px 20px;
    transition: all 0.3s;
    &:first-child {
      padding-left: 0;
    }
    &:not(:last-child) {
      border-right: 1px solid rgba(255, 255, 255, 0.3);
    }
  }
}

@media (min-width: $desktop-width) {
  .tour {
    left: calc(50% - 585px);
    width: 1170px;
  }
  
  .tour__item {
    position: relative;
    &::before {
      content: "";
      display: block;
      position: absolute;
      top: -1px;
      left: 0;
      width: 60%;
      height: 3px;
      background: transparent;
      transition: all 0.3s;
    }
    &:hover {
      color: #ffffff;
      &::before {
        background: #ffffff;
      }
      .tour__title {
        font-weight: 600;
      }
    }
  }
}