.menu__btn {
  position: fixed;
  z-index: 40;
  top: 20px;
  right: 15px;
  -moz-appearance: none;
  -webkit-appearance: none;
  font-size: 0;
  width: 30px;
  height: 30px;
  border: none;
  padding: 0;
  background-color: transparent;
  transition: all 0.3s;
  cursor: pointer;
}

.menu__hamburger {
  display: block;
  position: relative;
  height: 2px;
  background-color: #000000;
  transition: all 0.3s;
  &::before, &::after {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    width: 100%;
    height: inherit;
    background-color: #000000;
    transition: all 0.3s;
  }

  &::before {
    top: -8px;
  }
  &::after {
    bottom: -8px;
  }

  &--active {
    background-color: transparent;
   
    &::before {
      top: 0;
      transform: rotate(45deg);
      background-color: #ffffff;
    }
    &::after {
      bottom: 0;
      transform: rotate(-45deg);
      background-color: #ffffff;
    }
  }
}

.menu {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  position: fixed;
  z-index: 30;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  padding: 15px;
  background-color: #212121;
  transition: all 0.3s;
  &--closed {
    right: -100%;
  }

  &--open {
    right: 0;
    transition: all 0.3s;
  }
}

.menu__list {
  @include list-reset;
}

.menu__item {
  font-family: $marmelad;
  color: #ffffff;
  transition: all 0.3s;
  font-size: 20px;
  text-transform: uppercase;
  &:not(:last-child) {
    margin-bottom: 15px;
  }
  &:hover {
    color: $hover-color;
  }
}

.menu__link {
  border-color: $hover-color;
}

@media (min-width: $tablet-width) {
  .menu {
    width: 500px;
    padding: 30px 60px;
  }

  .menu__item {
    font-size: 28px;
    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }
}

@media (min-width: $desktop-width) {
  .menu {
    &--desktop {
      background-color: transparent;
      position: relative;
      top: auto;
      right: auto;
      width: auto;
      padding: 0;
      transition: unset;
      .menu__logo {
        display: none;
      }

      .social {
        display: none;
      }

      .menu__link {
        display: none;
      }

      .menu__list {
        display: flex;
      }

      .menu__item {
        font-size: 14px;
        &:not(:last-child) {
          margin-bottom: 0;
          margin-right: 40px;
        }
      }
    }
  }

  .menu__btn {
    &--opacity {
      opacity: 0;
    }
  }
}