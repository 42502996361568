.title {
  font-family: $marmelad;
  text-align: center;
  text-transform: uppercase;
  font-size: 28px;
  margin: 30px 0;
  font-weight: 700;
  &--small {
    color: $hover-color;
    font-size: 16px;
    line-height: 22px;
    font-weight: 400;
    margin: 0;
  }
  &--inner {
    text-transform: unset;
    text-align: left;
  }
}

@media (min-width: $desktop-width) {
  .title {
    margin: 90px 0;
    font-size: 36px;
    line-height: 65px;
    &--inner {
      font-size: 50px;
      line-height: 65px;
    }
  }
}