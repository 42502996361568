.reviews {
  padding: 40px 15px;
  .title {
    margin: 0;
    margin-bottom: 30px;
    &--small {
      text-align: center;
      margin-bottom: 5px;
    }
  }
}

.reviews__list {
  @include list-reset;
}

.reviews__img {
  width: 100%;
}

.reviews__title {
  font-family: $bad;
  font-size: 30px;
}

.reviews__date {
  font-size: 16px;
  line-height: 40px;
  letter-spacing: 0.03em;
}

.reviews__desc {
  font-size: 14px;
  line-height: 25px;
}

.reviews__author {
  font-size: 16px;
  line-height: 22px;
}

@media (min-width: $tablet-width) {
  .reviews__inner {
    display: flex;
    align-items: flex-start;
  }
  
  .reviews__img {
    width: 45%;
    margin-right: 20px;
  }
}

@media (min-width: $desktop-width) {
  .reviews__img {
    position: relative;
    width: 485px;
  }
  
  .reviews {
    @include page-wrapper;
    position: relative;
    &::before {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 20px;
      width: 201px;
      height: 296px;
      background-image: url("../../img/deer.png");
    }
    .title {
      margin-bottom: 100px;
      text-transform: unset;
    }
    
    .slick-arrow {
      position: absolute;
      z-index: 10;
      right: 15px;
      bottom: 15px;
      background-image: url(../../img/slider-arrow__white.svg);
      &:hover {
        background-image: url(../../img/slider__arrow--hover.svg);
      }
    }
    
    .slick-prev {
      right: 80px;
    }
  }
  
  .reviews__bg {
    content: "";
    display: block;
    position: absolute;
    top: calc(50% - 230px);
    right: 0;
    width: 600px;
    height: 600px;
    background-position: left;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url(../../img/reviews__bg.jpg);
    z-index: -1;
  }
  
  .reviews__inner {
    align-items: stretch;
    position: relative;
    background-image: url(../../img/reviews__item.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    &::before {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(81, 97, 108, 0.85);
    }
  }
  
  .reviews__content {
    position: relative;
    color: #ffffff;
    padding: 60px 40px;
  }
  
  .reviews__top {
    display: flex;
    //align-items: center;
    justify-content: space-between;
  }
  
  .reviews__title {
    max-width: 60%;
    line-height: 40px;
    margin-top: 0;
  }
  
  .reviews__desc {
    margin-bottom: 30px;
  }
}