.features {
  padding: 20px 15px;
}

.features__head {
  font-weight: 600;
  font-size: 16px;
  line-height: 30px;
  letter-spacing: 0.02em;
  color: #a18965;
  text-transform: uppercase;
}

.features__list {
  @include list-reset;
}

.features__desc {
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.02em;
}

@media (min-width: $tablet-width) {
  .features__list {
    display: flex;
    flex-wrap: wrap;
  }
  
  .features__item {
    width: calc(50% - 10px);
    margin: 0 5px;
  }
}

@media (min-width: $desktop-width) {
  .features {
    @include page-wrapper;
  }
  
  .features__list {
    margin-left: -15px;
    margin-right: -15px;
  }
  
  .features__item {
    width: 370px;
    margin: 0 15px;
    margin-bottom: 50px;
  }
}