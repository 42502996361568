.why {
  padding: 20px 15px;
  padding-top: 60px;
}

.why__title {
  color: $hover-color;
  font-size: 16px;
  line-height: 22px;
  font-weight: 400;
  margin: 0;
}

.why__quote {
  font-size: 16px;
  line-height: 25px;
  color: #868686;
}

.why__list {
  @include list-reset;
  margin: 30px 0;
}

.why__name {
  font-family: $marmelad;
  font-size: 16px;
  line-height: 23px;
  text-transform: uppercase;
  font-feature-settings: "tnum" on, "lnum" on;
  color: #0c435c;
  margin: 0 0 15px 0;
}

.why__desc {
  font-size: 14px;
  line-height: 21px;
  color: #6d6d6d;
}

.why__item {
  display: flex;
  &:hover {
    .why__icon {
      border-color: #0c435c;
    }
  }
  &:not(:last-child) {
    margin-bottom: 30px;
  }
}

.why__icon {
  content: "";
  display: block;
  width: 60px;
  min-width: 60px;
  height: 60px;
  border: 1px solid #d1d1d1;
  border-radius: 50%;
  padding: 12px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 65%;
  background-image: url("../../img/why__icon.png");
  margin-right: 15px;
  transition: all 0.3s;
}

.why__link {
  display: flex;
  justify-content: center;
  font-size: 16px;
  line-height: 27px;
  letter-spacing: 0.03em;
  border: 1px solid #006c9e;
  color: #006c9e;
  padding: 15.5px 40px;
  transition: all 0.3s;
  &:hover {
    background-color: #006c9e;
    color: #ffffff;
    svg {
      stroke: #ffffff;
      margin-left: 25px;
    }
  }
  span {
    float: left;
  }
  svg {
    float: left;
    width: 27px;
    height: 27px;
    stroke: #006c9e;
    margin-left: 15px;
    transition: all 0.3s;
  }
}

@media (min-width: $desktop-width) {
  .why {
    @include page-wrapper;
    padding-bottom: 180px;
    position: relative;
    &::after {
      content: "";
      display: block;
      position: absolute;
      bottom: 0;
      right: 0;
      width: 433px;
      height: 363px;
      background-image: url("../../img/crane.png");
    }
    .title {
      font-size: 60px;
    }
  }
  
  .why__content {
    max-width: 770px;
    margin-left: auto;
    position: relative;
  }
  
  .why__link {
    display: inline-block;
  }
  
  .why__list {
    display: flex;
    flex-wrap: wrap;
    margin: 40px 0;
  }
  
  .why__item {
    width: calc(50% - 15px);
    &:nth-child(2n) {
      margin-left: 30px;
    }
  }
  
  .why__quote {
    margin-left: -70px;
  }
  
  .why__bg {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 33%;
    height: 90%;
    background-repeat: no-repeat;
    background-position: right;
    background-size: cover;
    background-image: url("../../img/why__bg.jpg");
  }
}

@media (min-width: 1700px) {
  .why__bg {
    width: 740px;
  }
}