.page-tour {
  margin-top: 100px;
  
  .title {
    margin-top: 15px;
  }
}

.page-tour__cats {
  font-size: 16px;
  line-height: 22px;
  text-transform: uppercase;
  color: #a18965;
}

.page-tour__desc {
  font-size: 16px;
  line-height: 27px;
  margin-bottom: 40px;
}

.page-tour__link {
  display: inline-block;
  width: 100%;
  background: #fb7231;
  border-radius: 2px;
  font-size: 15px;
  line-height: 27px;
  text-transform: uppercase;
  color: #ffffff;
  padding: 15px 30px;
  text-align: center;
  transition: all 0.3s;
  &:hover {
    background: $hover-color;
  }
}

.page-tour__price {
  font-size: 18px;
  line-height: 25px;
  span {
    font-weight: 600;
    font-size: 32px;
    line-height: 25px;
  }
}

.page-tour__select {
  display: flex;
  align-items: center;
  position: relative;
  font-size: 18px;
  line-height: 25px;
  &::before {
    content: "";
    display: block;
    width: 31px;
    height: 31px;
    margin-right: 20px;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url(../../img/calendar.png);
  }
  &::after {
    content: "";
    display: block;
    margin-left: 20px;
    width: 14px;
    height: 14px;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url(../../img/select-down.png);
  }
}

.page-tour__price-wrapper {
  margin: 40px 0;
}

.page-tour__content {
  padding: 20px 15px;
  p {
    font-size: 16px;
    line-height: 25px;
  }
}

.page-tour__slider-list {
  @include list-reset;
  margin: 40px 0;
  img {
    width: 100%;
  }
}

.page-tour__top {
  padding: 20px 15px;
}

.page-tour__head {
  display: inline-block;
  font-size: 20px;
  line-height: 52px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  margin-bottom: 10px;
}

.page-tour__inclusive {
  padding: 20px 15px;
}

.page-tour__list {
  @include list-reset;
  margin: 0;
  &--on {
    .page-tour__item {
      &::before {
        background-image: url(../../img/inclusive-on.png);
      }
    }
  }

  &--off {
    .page-tour__item {
      &::before {
        background-image: url(../../img/inclusive-off.png);
      }
    }
  }

  &--need {
    .page-tour__item {
      &::before {
        background-image: url(../../img/inclusive-need.png);
      }
    }
  }
}

.page-tour__item {
  display: flex;
  align-items: flex-start;
  font-size: 16px;
  line-height: 52px;
  letter-spacing: 0.02em;
  text-transform: capitalize;
  &::before {
    content: "";
    display: block;
    width: 24px;
    min-width: 24px;
    height: 24px;
    margin-top: 14px;
    background-position: center;
    background-repeat: no-repeat;
    margin-right: 20px;
  }
}

.page-tour__col {
  margin-bottom: 40px;
}

@media (min-width: $tablet-width) {
  .page-tour__top {
    display: flex;
  }
  
  .page-tour__right {
    width: 400px;
  }
  
  .page-tour__inclusive {
    display: flex;
    flex-wrap: wrap;
  }
  
  .page-tour__col {
    margin-right: 40px;
  }
}

@media (min-width: $desktop-width) {
  .page-tour__map {
    iframe {
      min-height: 400px;
    }
  }
  
  .page-tour__inclusive {
    @include page-wrapper;
    margin: 100px 0;
  }
  
  .page-tour__col {
    flex-grow: 1;
    margin-right: 0;
    margin-bottom: 0;
  }
  
  .page-tour {
    margin-top: 160px;
    .title {
      font-size: 45px;
    }
  }
  
  .page-tour__right {
    margin-top: 30px;
    margin-left: 170px;
    min-width: 400px;
  }
  
  .page-tour__price-wrapper {
    display: flex;
    justify-content: space-between;
  }
  
  .page-tour__price {
    margin: 0;
  }
  
  .page-tour__top {
    @include page-wrapper;
  }
  
  .page-tour__content {
    @include page-wrapper;
    position: relative;
  }
  
  .page-tour__bg {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    width: 526px;
    height: 640px;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url(../../img/bear.png);
  }
  
  .page-tour__inner {
    display: flex;
    justify-content: center;
    p {
      width: calc(570px - 15px);
      margin: 0 15px;
    }
  }
  
  .page-tour__slider {
    position: relative;
    margin: 80px 0 80px 0;
    .slick-arrow {
      background-image: url(../../img/slider-arrow__white.svg);
      position: absolute;
      z-index: 10;
      left: calc(50% - 585px);
      &:hover {
        background-image: url(../../img/slider__arrow--hover.svg);
      }
    }
    
    .slick-next {
      top: calc(50% - 60px);
    }
    
    .slick-prev {
      top: calc(50%);
    }
    
    .events__counter {
      position: absolute;
      z-index: 20;
      bottom: 85px;
      left: calc(50% - 585px);
      color: #ffffff;
    }
    .events__current {
      color: #ffffff;
    }
    
    .events__length {
      color: rgba(255, 255, 255, 0.5);
    }
  }
}


.flextabs {
  display: flex;
  flex-wrap: wrap;
}

.flextabs__tab {
  width: 100%;
}

.flextabs__content {
  display: none;
  width: 100%;
}

.flextabs__content--active {
  display: block;
}


.flextabs__tab {
  /* shift all tabs to appear before content */
  order: -1;
  /* let tabs scale to fit multiple on each row */
  width: auto;
  margin: 0;
}

.flextabs__content--active {
  /* ignore states activated for multi (accordion) toggle view */
  display: none;
}
.flextabs__content--active--last {
  /* show the last activated item */
  display: block;
}

.page-tour__programm {
  padding: 20px 15px;
  p {
    font-size: 16px;
    line-height: 25px;
    letter-spacing: 0.02em;
    color: #000000;
  }
}

.flextabs__btns {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 40px;
  .flextabs__toggle {
    background: transparent;
    padding: 0;
    border: none;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.02em;
    border-bottom: 1px solid transparent;
  }
  
  .flextabs__tab {
    display: block;
    &:not(:last-child) {
      margin-right: 20px;
    }
  }
  .flextabs__toggle--active--last {
    color: $hover-color;
    border-color: $hover-color;
  }
}

@media (min-width: $tablet-width) {
  .flextabs__btns {
    flex-direction: column;
    min-width: 200px;
    margin-top: 16px;
    .flextabs__tab {
      &:not(:last-child) {
        margin-right: 0;
        margin-bottom: 30px;
      }
    }
  }
  
  .flextabs {
    display: flex;
    flex-wrap: nowrap;
  }
}

@media (min-width: $desktop-width) {
  .page-tour__programm {
    @include page-wrapper;
    margin: 150px 0 100px 0;
  }
  
  .flextabs__btns {
    width: auto;
  }
  
  .flextabs__main {
    max-width: 900px;
    margin-left: auto;
  }
}