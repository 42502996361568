
[data-accordion] [data-content] {
  overflow: hidden;
  max-height: 0;
}

/* Basic Theme */

[data-accordion] {
  line-height: 1;
}

[data-control],
[data-content] > * {
  //border-bottom: 1px solid #888;
  padding: 10px;
}

[data-content] [data-accordion] {
  border: 0;
  padding: 0;
}

[data-accordion] [data-control] {
  position: relative;
  padding-right: 40px;
}

[data-accordion] > [data-control]:after {
  content: "";
  position: absolute;
  top: calc(50% - 14.5px);
  right: 10px;
  font-size: 25px;
  font-weight: 200;
  color: #444;
  width: 29px;
  height: 29px;
  background: url(../../img/plus.svg) center center no-repeat;
  transition: all 0.3s;
  background-size: contain;
}

[data-accordion].open > [data-control]:after {
  background-image: url(../../img/plus-hover.svg);
  transform: rotate(45deg);
}


.services {
  padding: 20px 15px;
  margin-top: 80px;
  
  .why__link {
    margin-bottom: 40px;
    box-sizing: content-box;
  }
}

.services__desc {
  font-size: 16px;
  line-height: 25px;
}

.services__link {
  display: inline-block;
  font-size: 16px;
  line-height: 22px;
  margin: 40px 0;
  &:hover {
    color: $hover-color;
    .services__icon {
      fill: $hover-color;
    }
  }
  .services__icon {
    width: 33px;
    height: 33px;
    transition: all 0.3s;
    fill: #4c828f;
    margin-right: 15px;
    vertical-align: middle;
  }
}

.services__txt {
  font-size: 14px;
  line-height: 25px;
}

.services__video {
  display: block;
  font-size: 14px;
  line-height: 25px;
  letter-spacing: 0.02em;
  transition: all 0.3s;
  text-align: center;
  margin-top: 30px;
  &:hover {
    color: $hover-color;
    .services__icon {
      stroke: $hover-color;
    }
  }
  .services__icon {
    display: block;
    width: 64px;
    height: 64px;
    margin: 0 auto;
    margin-bottom: 20px;
    fill: transparent;
    transition: all 0.3s;
    stroke: rgba(149, 171, 185, 0.73);
  }
}

.services__name {
  font-size: 20px;
  line-height: 27px;
  cursor: pointer;
  padding-bottom: 20px;
  border-bottom: 1px solid rgba(139, 139, 139, 0.69);
}

.services__item {
  margin-bottom: 40px;
}

.services__list {
  margin: 40px 0;
}

.services__right {
  p {
    font-size: 14px;
    line-height: 25px;
  }
}

.services__img {
  @include list-reset;
  margin: 0;
  img {
    width: 100%;
  }
}

@media (min-width: $desktop-width) {
  .services {
    @include page-wrapper;
    padding-bottom: 0;
    .title {
      margin-bottom: 35px;
    }
    
    .slick-arrow {
      display: none;
    }
    
    .why__link {
      margin-top: 37px;
    }
  }
  
  .services__list {
    margin: 90px 0 0 0;
  }
  
  .services__desc {
    max-width: 530px;
  }
  
  .services__content {
    display: flex;
    justify-content: space-between;
  }
  
  .services__txt {
    max-width: 360px;
  }
  
  .services__inner {
    display: flex;
    padding: 60px 0;
  }
  
  .services__img {
    width: 600px;
    margin-right: 30px;
  }
  
  
}