.about {
  display: flex;
  align-items: flex-start;
  padding: 40px 15px;
  .title {
    margin: 0;
    text-align: left;
  }
}

.about__img {
  display: block;
  width: 25%;
  margin-right: 10px;
}

.about__inner {
  margin-top: 30px;
  margin-left: calc(-30% - 15px);
  font-size: 14px;
  line-height: 25px;
  color: #000000;
}

@media (min-width: $tablet-width) {
  .about__inner {
    margin-left: calc(-10% - 15px);
  }
  
  .about__img {
    width: 10%;
  }
}

@media (min-width: $desktop-width) {
  .about__inner {
    margin-left: 0;
    display: flex;
    margin-top: 25px;
  }
  
  .about__img {
    width: 270px;
    margin-right: 30px;
    margin-top: 30px;
  }
  
  .about {
    @include page-wrapper;
    padding-top: 150px;
    padding-bottom: 150px;
  }
}