.about-page {

}

.about-page__top {
  padding: 120px 15px 40px 15px;
  background-repeat: no-repeat;
  background-position: top;
  background-size: contain;
}

.about-page__bottom {
  padding: 20px 15px;
}

.about-page__menu {
  @include list-reset;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 40px;
}

.about-page__menu-item {
  a {
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.02em;
    border-bottom: 1px solid transparent;
    transition: all 0.3s;
    text-transform: uppercase;
    &:hover {
      color: $hover-color;
      border-color: $hover-color;
    }
  }
  &:not(:last-child) {
    margin-right: 10px;
    margin-bottom: 10px;
  }
}

.about-page__content {
  p {
    font-size: 16px;
    line-height: 30px;
    letter-spacing: 0.02em;
  }
}

@media (min-width: $tablet-width) {
  .about-page__top {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 300px;
    padding: 0;
  }
  
  .about-page__bottom {
    display: flex;
    position: relative;
  }
  
  .about-page__menu {
    flex-direction: column;
    min-width: 200px;
    margin-top: 16px;
  }
  
  .about-page__menu-item {
    &:not(:last-child) {
      margin-right: 0;
      margin-bottom: 30px;
    }
  }
}

@media (min-width: $desktop-width) {
  .about-page__top {
    @include page-wrapper;
    justify-content: flex-start;
    min-height: 75vh;
    background-size: unset;
    background-position: bottom;
  
    .title {
      text-align: left;
      font-size: 70px;
      margin-bottom: 70px;
      margin-top: 0;
    }
  }
  
  .about-page__bottom {
    @include page-wrapper;
    align-items: flex-start;
    padding-top: 120px;
    padding-bottom: 100px;
    background-position: left bottom;
    background-repeat: no-repeat;
    background-image: url(../../img/about_birds.png);
  }
  
  .about-page__content {
    margin-right: 100px;
    max-width: 770px;
    margin-left: auto;
  }
  
  .about-page__menu {
    position: sticky;
    top: 10px;
    left: 0;
    width: auto;
  }
}