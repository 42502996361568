@font-face {
  font-family: "Marmelad ";
  font-weight: 400;
  src: url(../../fonts/Marmelad-Regular.woff) format("woff"), url(../../fonts/Marmelad-Regular.woff2) format("woff2");
}

@font-face {
  font-family: "Open Sans ";
  font-weight: 300;
  src: url(../../fonts/OpenSans-Light.woff) format("woff"), url(../../fonts/OpenSans-Light.woff2) format("woff2");
}

@font-face {
  font-family: "Open Sans ";
  font-weight: 400;
  src: url(../../fonts/OpenSans-Regular.woff) format("woff"), url(../../fonts/OpenSans-Regular.woff) format("woff2");
}

@font-face {
  font-family: "Open Sans ";
  font-weight: 600;
  src: url(../../fonts/OpenSans-SemiBold.woff) format("woff"), url(../../fonts/OpenSans-SemiBold.woff) format("woff2");
}

@font-face {
  font-family: "Open Sans ";
  font-weight: 700;
  src: url(../../fonts/OpenSans-Bold.woff) format("woff"), url(../../fonts/OpenSans-Bold.woff2) format("woff2");
}

@font-face {
  font-family: "Bad Script ";
  font-weight: 400;
  src: url(../../fonts/bad-script.woff) format("woff"), url(../../fonts/bad-script.woff2) format("woff2");
}