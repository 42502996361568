body {
  font-family: $sans;
  font-weight: 400;
  font-size: 16px;
}

* {
  box-sizing: border-box;
}

a {
  color: inherit;
  text-decoration: none;
}

.slick-dots {
  @include list-reset;
  display: flex;
  justify-content: center;
  margin: 20px;
  font-size: 0;
  button {
    content: "";
    display: block;
    width: 8px;
    height: 8px;
    background: #0f6cc6;
    opacity: 0.6;
    padding: 0;
    border-radius: 50%;
    transition: all 0.3s;
  }
  li {
    margin: 5px;
  }
  .slick-active {
    button {
      opacity: 1;
    }
  }
}

.slick-arrow {
  font-size: 0;
  width: 55px;
  height: 55px;
  background-color: transparent;
  border: none;
  padding: 0;
  background-image: url("../../img/slider__arrow.svg");
  transition: all 0.3s;
  cursor: pointer;
  &:hover {
    background-image: url("../../img/slider__arrow--hover.svg");
  }
}

.slick-prev {
  transform: rotate(180deg);
}

.slick-next {
}